import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'management-app';

  constructor(
    private router: Router,
    private storageService: StorageService
  ){}

  isLogin() {
    if (this.router.url.indexOf('/login') > -1) {
      return true;
    }
    return false;
  }

  onLogout(){
    this.storageService.removeToken();
    window.location.reload();
  }
}
