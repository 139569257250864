<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ngx-extended-pdf-viewer [src]="document" useBrowserLocale="true" height="90vh" [zoom]="minZoom" *ngIf="document !== null"></ngx-extended-pdf-viewer>

      <img class="image-view" [src]="image" *ngIf="image!== null" alt="no image"/>
    </div>
  </div>
</div>
