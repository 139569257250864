import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { catchError } from 'rxjs/operators';
 
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(
        private storageService: StorageService
    ){}

   intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
     
    const skipPaths = ['/login', '/reset-password'];

    const shouldSkip = skipPaths.find(element => { 
        return req.url.indexOf(element) > -1
    });

    if(!shouldSkip){
        req = req.clone({
            headers: req.headers.set('authorization', `Bearer ${this.storageService.getToken()}`)
        });
        
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if(err.status === 401 && err.error.error === 'Invalid Access Token'){
                    this.storageService.removeToken();
                    window.location.reload();
                }
                return throwError(err);
            })
        );

    }else{
        return next.handle(req); 
    }
   }
}