import { Component, OnInit } from '@angular/core';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {ApiBeneficiaryContractsService} from '../../../http/api-beneficiary-contracts.service';


export interface ConfirmModel {
  beneficiaryId: string;
  contractId: number;
  estimateSeries: string;
  estimateNumber: number;
}

@Component({
  selector: 'app-paid--modal',
  templateUrl: './paid-modal.component.html',
  styleUrls: ['./paid-modal.component.css']
})
export class PaidModalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  beneficiaryId: string;
  contractId: number;
  estimateSeries: string;
  estimateNumber: number;
  constructor(private apiService: ApiBeneficiaryContractsService) {
    super();
  }
  confirm() {
    this.apiService.paidStatus(this.beneficiaryId, this.contractId).subscribe(
      (response) => console.log('res', response),
      (err) => console.log('err', err),
    );
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }
}
