import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, pluck, tap} from 'rxjs/operators';
import {WeekDaysMapping} from './medical-staff/api-medical-staff-schedule.service';
import {MedicalGroup} from './transport/medical-group.model';

export const Status = {
  0: 'Draft',
  1: 'Trebuie semnat',
  2: 'Plata in asteptare',
  3: 'In derulare',
  4: 'Inchis'
};

export const Color = {
  0: '#f4f5f8',
  1: '#ffc409',
  2: '#3dc2ff',
  3: '#2dd36f',
  4: '#92949'
};

@Injectable({ providedIn: 'root' })
export class ApiBeneficiaryContractsService {

  color: string;
    constructor(
        private http: HttpClient
  ){}

  getContracts(beneficiaryId: string) {
    return this.http.get(`${environment.APIURL}/admin/beneficiary/${beneficiaryId}/contracts`, {}).pipe(
      map((response: any) => {
        response.forEach(contract => {
          contract.color = Color[contract.status];
          contract.statusName = Status[contract.status];

        });
        return response;
      }),
    );
  }

  paidStatus(beneficiaryId: string, contractId: number) {
      return this.http.post(`${environment.APIURL}/admin/beneficiary/${beneficiaryId}/contracts/${contractId}/estimates`, {});
  }
}
