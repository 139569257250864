<nav class="navbar navbar-dark bg-dark navbar-expand-md" *ngIf="!isLogin()">
  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item" routerLink="/beneficiari" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <a class="nav-link" href="#" routerLink="/beneficiari">Beneficiari</a>
      </li>

      <li class="nav-item" routerLink="/cadre-medicale" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <a class="nav-link" href="#" routerLink="/cadre-medicale">Cadre Medicale</a>
      </li>

      <li class="nav-item" routerLink="/servicii-medicale" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
        <a class="nav-link" href="#" routerLink="/servicii-medicale">Servicii Medicale</a>
      </li>
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item">
          <a class="nav-link text-danger" href="#" (click)="onLogout()">Logout</a>
      </li>
  </ul>
  </div>
</nav>

<div>
  <router-outlet></router-outlet>
</div>
