import {Component, EventEmitter, Input, OnInit, Output, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public minZoom = 75;
  public document: Blob = null;
  public image: string | ArrayBuffer = null;

  @Input()
  docs: Blob;

  @Input()
  documentType: string;

  @Output()
  cancel: EventEmitter<null> = new EventEmitter<null>();

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

    if (this.documentType === 'pdf') {
      this.document = this.docs;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(this.docs);
      reader.onloadend = () => {
        this.image =  this.sanitizer.sanitize(SecurityContext.URL, reader.result);
      };
    }
  }

  onCancel(): void{
    this.cancel.emit(null);
  }

}
