import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(){}

    getToken(){
        return localStorage.getItem('token');
    }
    
    setToken(token: string){
        localStorage.setItem('token', token);
    }

    removeToken(){
        localStorage.removeItem('token');
    }
}