<div class="modal-content">
  <div class="modal-header">
    <h4>Plata in asteptare</h4>
  </div>
  <div class="modal-body">
    Proforma {{estimateSeries}} cu numarul {{estimateNumber}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="cancel()" >Cancel</button>
    <button type="button" class="btn btn btn-success" (click)="confirm()">Platit</button>
  </div>

</div>
