import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './http/token-interceptor.service';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { ModalComponent } from './components/modals/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {SimpleModalModule} from 'ngx-simple-modal';
import { PaidModalComponent } from './components/modals/paid-modal/paid-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    PaidModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    SimpleModalModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
